<!--
  @传参格式要求：
  formData:为对象,flag:为字符串1是小文件上传2是大文件上传,regex:为对象,accept:为字符串,limit:为数字
@使用方法:
<BigFile
:formData="formData"   //传值
:flag="flag"   //是否为大文件上传的标志量  1为小文件上传2为大文件上传
:regex="regex"   //文件命名正则验证
:accept="accept"   //上传文件类型
:limit="limit"   //限制上传文件上传个数
@handleRmove="handleRmove"   //删除文件回调
@changeFile="changeFile"   //文件改变回调
@uploadSpeed="uploadSpeed"   //上传进度回调
@uploadEvent="uploadEvent"   //上传完成回调
></BigFile>
-->
<template>
  <div>
    <div>
      <div>
        <div>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-upload"
            @click="upload($event)"
            >{{ $t("点击上传") }}</el-button
          >
          <slot></slot>
        </div>
        <div
          class="FileList"
          v-if="JSON.stringify(percentData) != '{}'"
          v-for="(item, key) in percentData"
          :key="key">
          <div class="label">{{ upflag[key] }}</div>
          <div class="StatusBox">
            <el-progress
              v-if="percentData[key] != 100"
              type="circle"
              :percentage="percentData[key]"
              class="jindutiao"></el-progress>
            <i class="el-icon-upload-success el-icon-circle-check" v-else></i>

            <i
              class="el-icon-circle-close el-icon-upload-close"
              @click="yichu(key)"></i>
          </div>
        </div>
      </div>

      <el-upload
        v-show="false"
        class="upload-demo"
        action
        multiple
        :accept="accept"
        ref="uploadfile"
        :auto-upload="false"
        :on-change="
          (file) => {
            return changeFile(file);
          }
        "
        :limit="limit"
        :on-exceed="
          (files, fileList) => {
            return handleExceed(files, fileList);
          }
        "
        :before-remove="
          (files, fileList) => {
            return handleRmove(files, fileList);
          }
        "
        :file-list="form['filelist']">
        <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
      </el-upload>
    </div>
  </div>
</template>

<script>
import { bigFileUpload } from "./uploadBid";
export default {
  name: "bigUpload",
  props: {
    //传值
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    flag: {
      type: String, //是否为大文件上传的标志量  1为小文件上传2为大文件上传
      default: "2",
    },
    regex: {
      type: Object, //文件命名正则验证
      default: () => {
        return {
          regex: /^[a-zA-Z0-9\._]+$/,
          message: "文件名只能包含字母、数字、下划线",
        };
      },
    },
    accept: {
      type: String, //上传文件类型
      default: "",
    },
    limit: {
      type: Number, //限制上传文件上传个数
      default: 1,
    },
  },
  data() {
    return {
      form: {
        filelist: [],
      },
      postFlag: true,
      upflag: {},
      percentData: {},
      timeDate: null, //上传文件的时间戳
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
  },
  methods: {
    // 组件的方法
    async upload(evt, val, type) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      if ("changeFile" in this.$listeners) {
        const jixu = await this.$listeners["changeFile"]();
        console.log(jixu);
        if (jixu) {
          this.$refs["uploadfile"].$refs["upload-inner"].handleClick();
        }
      } else {
        this.$refs["uploadfile"].$refs["upload-inner"].handleClick();
      }
    },
    changeFile(file) {
      console.log(file);
      const fileTypeMap = {
        ".jpg": this.$t("图片"),
        ".jpeg": this.$t("图片"),
        ".png": this.$t("图片"),
        ".gif": this.$t("图片"),
        ".pdf": this.$t("PDF文件"),
        ".doc": this.$t("Word文档"),
        ".docx": this.$t("Word文档"),
        ".xls": this.$t("Excel表格"),
        ".xlsx": this.$t("Excel表格"),
        ".mp4": this.$t("视频文件"),
        ".mp3": this.$t("音频文件"),
        ".txt": this.$t("txt文件"),
        ".csv": this.$t("csv文件"),
        // 可以根据需要添加更多的文件类型
      };
      file.label = this.$t("上传文件");
      const regex = this.regex.regex;
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      const Name = fileName.substring(0, fileName.lastIndexOf("."));
      if (!regex.test(Name)) {
        this.$alert(this.regex.message, "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.postFlag = false;
            this.form = {
              filelist: [],
            };
          },
        });
        return;
      }

      this.form["filelist"].push(file);
      this.percentData[file.uid] = 0;

      if (file) {
        if (this.accept != "") {
          var arr = this.accept.split(",");

          if (arr.includes(fileType)) {
            // if (this.limit != 1) {
            this.upflag[file.uid] = file.uid + file.raw.name;
            // } else {
            //   this.upflag[file.uid] = file.raw.name;
            // }

            this.percentData[file.uid] = 0;
            this.postFlag = true;
            this.uploadFile(file.raw, 0);
          } else {
            var error = arr.map((item) => {
              return fileTypeMap[item.toLowerCase()]
                ? fileTypeMap[item.toLowerCase()]
                : this.$t("未知文件类型");
            });
            if (error.length > 1) {
              error = error.join(this.$t("或"));
            } else {
              error = error[0];
            }
            this.form["filelist"].pop();
            this.$message.error(`${this.$t("请上传")}${error}`);
          }
        } else {
          // if (this.limit != 1) {
          this.upflag[file.uid] = file.uid + file.raw.name;
          // } else {
          //   this.upflag[file.uid] = file.raw.name;
          // }
          this.percentData[file.uid] = 0;
          this.postFlag = true;
          this.uploadFile(file.raw, 0);
        }
      } else {
        this.postFlag = false;
      }
    },
    yichu(uid) {
      console.log(uid, this.form["filelist"]);
      var json = {};
      this.form["filelist"].map((item) => {
        json[item.uid] = item;
      });
      console.log(json);
      if (this.percentData[uid] != 100) {
        this.quxiao(json[uid]);
      }
      this.handleRmove(json[uid], this.form["filelist"]);
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
      this.$message({
        showClose: true,
        message: this.$t("超出上传限制！"),
        type: "error",
        offset: "100",
      });
    },
    quxiao(file) {
      console.log(file, "取消上传");
    },
    handleRmove(file, fileList) {
      console.log(file, fileList);
      this.form["filelist"] = this.form["filelist"].filter(
        (item) => item !== file
      );
      delete this.upflag[file.uid];
      delete this.percentData[file.uid];

      this.postFlag = false;

      this.$emit("handleRmove", file);
      // this.upflag["file"] = null;
    },
    uploadFile(file, skip) {
      var _this = this;
      var formData = new FormData(); //初始化一个FormData对象
      if (_this.flag == "2") {
        //进行切片
        var blockSize = 1 * 1000 * 1000; //2 * 1024 * 1024; //10M每块的大小
        var nextSize = Math.min((skip + 1) * blockSize, file.size); //读取到结束位置
        var fileData = file.slice(skip * blockSize, nextSize); //截取 部分文件 块
      } else {
        //不进行切片
        var blockSize = file.size;
        var nextSize = file.size;
        var fileData = file;
      }
      var fileName = file.name;
      // if (this.limit != 1) {
      fileName = file.uid + fileName;
      // }
      //文件名添加随机值，同一个文件保持一致
      // if (skip == 0) {
      //   _this.timeDate = new Date().getTime();
      //   fileName = _this.timeDate + file.name;
      // }
      // console.log(_this.timeDate + file.name);
      // this.$emit("changeFile", _this.timeDate + file.name);
      formData.append("file", fileData); //将 部分文件 塞入FormData
      formData.append("oldName", fileName); //文件原始名字
      formData.append("fileN", fileName + ".temp" + skip); //保存文件名git
      formData.append("index", skip); //保存当前碎片序列
      formData.append("total", Math.ceil(file.size / blockSize)); //保存总共文件序列
      // formData.append("flag", _this.flag); // 是否为大文件上传的标志量  1为小文件上传2为大文件上传
      for (var key in _this.formData) {
        if (key != "requestUrl") {
          formData.append(key, _this.formData[key]); //将 表单数据 塞入FormData
        }
      }
      if (fileName == _this.upflag[file.uid]) {
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        };
        var requestUrl = null;
        if (_this.formData.requestUrl) {
          requestUrl = _this.formData.requestUrl;
        }
        bigFileUpload(formData, config, requestUrl)
          .then((data) => {
            console.log(data);
            if (skip + 1 <= Math.ceil(file.size / blockSize)) {
              if (file.uid in _this.percentData) {
                _this.percentData[file.uid] = Math.trunc(
                  Number(((skip + 1) / Math.ceil(file.size / blockSize)) * 100)
                );
                _this.$emit("uploadSpeed", _this.percentData[file.uid]);
                this.$forceUpdate();
              }
            } else {
              return;
            }
            if (file.size <= nextSize) {
              //如果上传完成，则跳出继续上传
              // _this.$message(data.msg)
              _this.$emit("uploadEvent", fileName);
              return;
            }
            if (data.code == 1) {
              console.log(data);
              _this.uploadFile(file, data.index);
            } else {
              if (_this.postFlag) {
                _this.uploadFile(file, ++skip); //递归调用
              } else {
                // this.postFlag = true;
                return;
              }
            }
          })
          .catch((res) => {
            // _this.errBtn = true;
          });
      }
    },
    /* 生成文件切片 */
    createFileChunk(file, size = SIZE) {
      const fileChunkList = [];
      let cur = 0;
      while (cur < file.size) {
        fileChunkList.push({ file: file.slice(cur, cur + size) });
        cur += size;
      }
      return fileChunkList;
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
.FileList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: pxttrem(10);
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background-color: #f5f5f5;
    .label {
      color: #409eff;
      cursor: pointer;
    }
    .StatusBox {
      .el-icon-upload-success {
        display: none;
      }
      .el-icon-upload-close {
        display: inline-block;
      }
    }
  }
  .StatusBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .jindutiao {
      width: pxttrem(30);
      height: pxttrem(30);
      ::v-deep .el-progress-circle {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .el-icon-upload-success {
      display: inline-block;
      color: #67c23a;
    }
    .el-icon-upload-close {
      cursor: pointer;
      display: none;
      margin-left: pxttrem(10);
    }
  }
}
</style>
